<template>
  <div id="container">
    <Loading v-if="$global.state.loading" />
    <v-card class="transparent" flat v-else>
      <v-card-title>
        <span class="font-weight-bold">
          {{ $route.name }}
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openAddDialog()">
          <v-icon>mdi-plus</v-icon>
          {{ meta.single }}
        </v-btn>
      </v-card-title>
      <v-card-text dir="ltr">
        <DxDiagram
          id="diagram"
          style="direction: ltr;"
          ref="diagram"
          custom-shape-template="CustomShapeTemplate"
          custom-shape-toolbox-template="CustomShapeToolboxTemplate"
          @request-layout-update="onRequestLayoutUpdate"
          :read-only="true"
        >
          <DxCustomShape
            :type="'employee'"
            :category="'employee'"
            :base-type="'rectangle'"
            :title="'New Employee'"
            :default-width="2.5"
            :default-height="1.5"
            :allow-edit-text="false"
            :allow-resize="false"
          />
          <template #CustomShapeTemplate="{ data }">
            <CustomShapeTemplate
              :node="data.dataItem"
            />
          </template>

          <DxNodes
            :data-source="$global.state.organizations"
            :key-expr="'id'"
            :type-expr="itemTypeExpr"
            :custom-data-expr="itemCustomDataExpr"
            :parent-key-expr="'parentId'"
          >
            <DxAutoLayout :type="'tree'" />
          </DxNodes>
        </DxDiagram>
      </v-card-text>
    </v-card>
    
    <Dialog />
  </div>
</template>
<script>
import {
  DxDiagram,
  DxNodes,
  DxAutoLayout,
  DxCustomShape,
} from "devextreme-vue/diagram";
import ArrayStore from "devextreme/data/array_store";
import CustomShapeTemplate from "./CustomShapeTemplate.vue";
import service from "./data.js";
import dialog from "./dialog";
export default {
  components: {
    DxDiagram,
    DxNodes,
    DxAutoLayout,
    DxCustomShape,

    Dialog: dialog,
    CustomShapeTemplate,
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    const that = this;
    this.generatedID = 100;
    return {
      employees: service.getEmployees(),
      dataSource: new ArrayStore({
        key: "id",
        data: this.organizations,
      }),
      currentEmployee: {},
      popupVisible: false,
      organizations: [],
    };
  },
  methods: {
    getItems() {
      this.$global.dispatch(`get${this.meta.endPoint}`);
    },
    itemTypeExpr() {
      return "employee";
    },
    itemCustomDataExpr(obj, value) {
      if (value === undefined) {
        return {
          Full_Name: obj.Full_Name,
          Prefix: obj.Prefix,
          Title: obj.Title,
          City: obj.City,
          State: obj.State,
          Email: obj.Email,
          Skype: obj.Skype,
          Mobile_Phone: obj.Mobile_Phone,
        };
      }
      obj.Full_Name = value.Full_Name;
      obj.Prefix = value.Prefix;
      obj.Title = value.Title;
      obj.City = value.City;
      obj.State = value.State;
      obj.Email = value.Email;
      obj.Skype = value.Skype;
      obj.Mobile_Phone = value.Mobile_Phone;
      return null;
    },
    onRequestLayoutUpdate(e) {
      for (let i = 0; i < e.changes.length; i += 1) {
        if (e.changes[i].type === "remove") {
          e.allowed = true;
        } else if (
          e.changes[i].data.Head_ID !== undefined &&
          e.changes[i].data.Head_ID !== null
        ) {
          e.allowed = true;
        }
      }
    },
   openAddDialog() {
      console.log("test");
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
  },
  mounted() {
    this.$global.state.loading = true;
    this.getItems();
    this.$global.dispatch(`getOrganizationType`);
    this.$global.dispatch(`getUser`);
    this.$global.state.loading = false;
    this.$eventBus.$on(`refresh`, () => {
      console.log("refresh");
      this.getItems();
    });
  },
};
</script>
<style scoped>
#diagram {
  height: 800px;
}

.dx-popup-content {
  padding: 0;
}

.dx-popup-content .dx-fieldset.buttons {
  display: flex;
  justify-content: flex-end;
}

.dx-popup-content .dx-fieldset.buttons > * {
  margin-left: 8px;
}
</style>
