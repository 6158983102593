<template>
  <svg class="template">
    <text class="template-name" x="50%" y="20%">
      {{ node.name }}
    </text>
    <text class="template-title" x="50%" y="45%">
      {{ node.organizationType.name }}
    </text>
    <text class="template-button" @click="openAddDialog(item)" x="25%" y="85%">
      اضافة
    </text>
    <text class="template-button" @click="deleteItem(item.id)" x="50%" y="85%">
      حذف
    </text>
    <text class="template-button" @click="openEditDialog(item)" x="75%" y="85%">
      تعديل
    </text>
  </svg>
</template>
<script>
// import vuetify styles
import "../../../node_modules/vuetify/dist/vuetify.min.css";

export default {
  props: {
    node: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  created() {
    this.item = {
      id: this.node.id,
      name: this.node.name,
      level: 0,
      parentId: this.node.parentId == -1 ? null : this.node.parentId,
      organizationTypeId: this.node.organizationTypeId,
      userId: this.node.userId,
    };
  },
  mounted() {
    // this.reset();

    // this.$eventBus.$on("fill-fields", () => {
    //   this.item = this.$store.state.itemDetails;
    // });
  },
  methods: {
    openAddDialog() {
      var item = {
        dialogType: "add",
        parentId: this.node.id,
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },
    deleteItem(id, i) {
      this.$service.deleteItem(id, `${this.$route.meta.endPoint}`, `delete`);
      this.$eventBus.$once(`delete`, () => {
        this.$eventBus.$emit(`refresh`);
      });
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      console.log('zaid',item);
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
};
</script>

<style lang="scss">
// * {
//   font-family: "Cairo", sans-serif;
// }
#diagram .template .template-name {
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
  word-wrap: break-word;
}

#diagram .template .template-title {
  font-style: italic;
  font-size: 18px;
}

#diagram .template .template-button {
  cursor: pointer;
  font-size: 12pt;
  font-weight: bold;
  background: #ddd;
}

#diagram .template .template-button:hover {
  text-decoration: underline;
}
</style>
