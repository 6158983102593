<template>
  <div>
    <v-dialog v-model="$store.state.dialog" width="800">
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title
            >{{
              $store.state.itemDetails.dialogType == "add"
                ? $t("idhafh")
                : $t("edit")
            }}
            {{ this.$route.meta.single }}
          </v-toolbar-title>
          <v-spacer />

          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form @submit.prevent="submit()" ref="form" >
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">{{ $t('alism') }} <span class="required">*</span></h3>
                    <v-text-field
                      v-model="item.name"
                      :placeholder="$t('alism')"
                      :rules="[$global.state.required()]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      تابعة لمنظمة <span class="required"></span>
                    </h3>
                    <v-autocomplete
                      v-model="item.parentId"
                      :items="$global.state.organizations"
                      item-text="name"
                      item-value="id"
                      hide-details="auto"
                      outlined
                      :rules="[$global.state.required()]"
                      filled
                      small-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">النوع <span class="required">*</span></h3>
                    <v-autocomplete
                      v-model="item.organizationTypeId"
                      :items="$global.state.organizationTypes"
                      item-text="name"
                      item-value="id"
                      hide-details="auto"
                      outlined
                      :rules="[$global.state.required()]"
                      filled
                      small-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      مدير المنظمة <span class="required"></span>
                    </h3>
                    <v-autocomplete
                      v-model="userId"
                      :items="$global.state.users"
                      item-text="fullName"
                      item-value="id"
                      hide-details="auto"
                      outlined
                      :rules="[$global.state.required()]"
                      filled
                      small-chips
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2" justify="end">
                <v-btn
                  @click="close()"
                  x-large
                  color="error"
                  class="mx-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  :disabled="item.organizationTypeId == '' || item.name == ''"
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
      userId: "",
    };
  },

  mounted() {
    this.reset();
    this.$eventBus.$on("fill-fields", () => {
      try {
        console.log(this.$store.state.itemDetails);
        this.item = this.$store.state.itemDetails;
      } catch (error) {
        console.log(error);
        return 0;
      }
    });
  },

  methods: {
    reset() {
      this.item = {
        name: "",
        level: 0,
        parentId: null,
        organizationTypeId: "",
        // userId: "",
      };
    },

    submit() {
      if (this.userId) {
        this.item.userId = this.userId;
      }
      if (this.$store.state.itemDetails.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },

    addItem() {
      this.$global.state.loading = true;
      if (this.$store.state.itemDetails.parentId != undefined) {
        if (this.$store.state.itemDetails.parentId < 0) {
          this.item.parentId = null;
        } else {
          this.item.parentId = this.$store.state.itemDetails.parentId;
        }
      }
      this.$http
        .post(`${this.$route.meta.endPoint}`, this.item)
        .then((res) => {
          this.$eventBus.$emit(`refresh`);
          this.$service.success(this.$t("tm-alidhafh-bnjah"));
          this.close();
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    async editItem() {
      this.$global.state.loading = true;
      const res = await this.$http.put(
        `${this.$route.meta.endPoint}/${this.item.id}`,
        this.item
      );
      try {
        this.$eventBus.$emit(`refresh`);
        this.close();
        this.$service.success(this.$t('tm-altadyl-bnjah'))
      } catch (err) {
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
